<template>
  <div class="CategoryDeleteModal">
    <v-dialog v-model="categoryDeleteModal" persistent width="700px" @keydown.esc="closeModal" @keydown.enter="removeCategory">
      <v-card>
        <v-card-title class="headline">
          {{ $t('Delete Category') }} {{ category.name }}
        </v-card-title>
        <v-card-text>
          <v-progress-linear v-if="loading" indeterminate color="blue" class="mb-0" />
          <v-container v-else>
            {{ $t('This action will not be possible to revert') }}.
            {{ $t("When category will be deleted all it's products will be unassigned from the category!") }}
          </v-container>
        </v-card-text>
        <v-card-actions v-if="!loading">
          <v-spacer></v-spacer>
          <v-btn @click="removeCategory" class="white--text mr-4 my-3" color="primary" depressed text>
            {{ $t('Delete') }}
          </v-btn>
          <v-btn  @click="closeModal" class="mr-2 mt-0 white--text" color="red" depressed text>
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CategoryDeleteModal',
  data () {
    return {
      categoryDeleteModal: false,
      loading: false
    }
  },
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      deleteCategory: 'catalog/category/deleteCategory'
    }),
    openDialog () {
      this.categoryDeleteModal = true
    },
    closeModal () {
      this.categoryDeleteModal = false
    },
    removeCategory () {
      this.loading = true
      this.deleteCategory({ token: this.token, category_id: this.category.entity_id })
        .finally(() => {
          this.loading = false
          this.$bus.$emit('close-category-form', 'delete')
          this.closeModal()
        })
    }
  }
}
</script>
