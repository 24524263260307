<template>
  <section>
    <v-card>
      <div id="scroll-target" class="scroll-y">
        <v-card-title class="search" v-if="resizePage >= 600">
          <v-text-field v-model="search" @input="debouncedSearch" append-icon="mdi-magnify" :label="$t('Search')"
                        :placeholder="$t('Start typing product name...')"
                        single-line hide-details
          />
          <div class="desktop-buttons">
            <v-btn color="primary" dark class="ml-2" @click="exportFile">{{ $t('Export') }}</v-btn>
            <v-btn color="primary" dark class="ml-2" @click="$refs.file.click()">{{ $t('Import') }}<input type="file" ref="file" accept=".csv" v-show="false" @change="uploadFile"></v-btn>
            <v-btn color="primary" dark class="ml-2" @click="openAddNewDialog">{{ $t('New Item') }}</v-btn>
          </div>
        </v-card-title>
        <v-card-title class="mobile-search" v-else>
          <div class="desktop-buttons">
            <v-btn color="primary" dark class="ml-2" @click="exportFile">{{ $t('Export') }}</v-btn>
            <v-btn color="primary" dark class="ml-2" @click="$refs.file.click()">{{ $t('Import') }}<input type="file" ref="file" accept=".csv" v-show="false" @change="uploadFile"></v-btn>
            <v-btn color="primary" dark class="ml-2" @click="openAddNewDialog">{{ $t('New Item') }}</v-btn>
          </div>
          <v-text-field v-model="search" @input="debouncedSearch" class="mobile-search-field" append-icon="mdi-magnify" :label="$t('Search')"
                        :placeholder="$t('Start typing product name...')"
                        single-line hide-details
          />
        </v-card-title>
        <v-data-table ref="myTable"
                      :headers="headers"
                      :items="products"
                      :search="search"
                      :loading="loading"
                      :options.sync="pagination.options"
                      :server-items-length="pagination.total"
                      @update:options="updateOptions"
                      item-key="entity_id"
                      width="100"
                      class="elevation-1 orders-table"
                      :footer-props="{'items-per-page-text':$t('Rows per page:')}"
        >
          <template #body="props">
            <draggable
              :list="props.items"
              :disabled="!showSort"
              ghost-class="sortable-ghost"
              chosen-class="sortable-chosen"
              drag-class="sortable-drag"
              tag="tbody"
              draggable=".mobile"
              @change="updateCategoryPosition"
            >
              <template v-if="resizePage >= 600">
                <category-product-list-table-item v-for="item in props.items" :item="item" :key="item.sku" :category-id="categoryId" :showSort="showSort"/>
              </template>
              <template v-else>
                <category-product-list-table-item-mobile v-for="item in props.items" :item="item" :key="item.sku" :category-id="categoryId" :showSort="showSort"/>
              </template>
            </draggable>
          </template>
        </v-data-table>
      </div>
    </v-card>

  </section>
</template>
<script>
import Draggable from "vuedraggable";
import CategoryProductListTableItem from '@/components/Catalog/Categories/CategoryProductListTableItem'
import CategoryProductListTableItemMobile from '@/components/Catalog/Categories/CategoryProductListTableItemMobile'
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ProductsListTable',
  components: {
    Draggable,
    CategoryProductListTableItem,
    CategoryProductListTableItemMobile
  },
  props: {
    products: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    pagination: {
      type: Object,
      required: false
    },
    showSort: {
      type: Boolean,
      default: true
    },
    categoryId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      search: '',
      headers: [
        { text: '', align: 'left', width: '5%', sortable: false },
        { text: this.$t('Name'),  align: 'left', width: '35%', sortable: false, value: 'name' },
        { text: this.$t('SKU'),  align: 'left', width: '35%', sortable: false, value: 'sku' },
        { text: this.$t('Price'),  align: 'left', width: '15%', sortable: false, value: 'price' },
        { text: this.$t('Status'), align: 'left', width: '5%', sortable: false, value: 'status' },
        { text: this.$t('Action'), align: 'left', width: '5%', sortable: false, value: 'actions' }
      ],
      mobileButtons: false,
      mobileHeaders: [
        { text: this.$t('Name'),  align: 'left', sortable: false, value: 'name' },
        { text: this.$t('Price'),  align: 'left', sortable: false, value: 'price' },
        { text: this.$t('Status'), align: 'left', sortable: false, value: 'status' },
      ],

    }
  },
  computed: {
  ...mapGetters({
      token: 'user/getAccessToken',
      isInitPositionsRequired: 'catalog/category/isProductPositionsInitRequired'
    }),
    resizePage () {
      return window.innerWidth
    }
  },
  methods: {
    ...mapActions({
      updateCategory: 'catalog/category/save',
      setEmptyPositionsFlag: 'catalog/category/setEmptyPositionsFlag'
    }),

    debouncedSearch: _.debounce(function() {
      this.searched();
    }, 500),
    updateOptions (value) {
      value.search = this.search
      this.$emit('update:options', value)
    },
    searched () {
      this.$emit('searched', this.search)
    },
    openAddNewDialog () {
      this.$bus.$emit('catalog-product-new', true)
    },
    updatePositions (payload) {
      let { category } = payload
      let updatedProducts = (category) ? category['products'] : false
      if (!category || !updatedProducts) {
        return
      }
      this.products.forEach(product => {
        let updatedProduct = updatedProducts.find(p => p.product_id === product.entity_id)
        product.position = updatedProduct.position
      })
      this.setEmptyPositionsFlag(false)
    },
    async uploadFile (event) {
      if (event.target.files[0] !== undefined) {
        let file = event.target.files[0]
        this.$bus.$emit('notification-progress-start')
        await this.$store.dispatch('catalog/product/importProducts', { file, token: this.token, type: 'products'})
          .then(res => {
            this.$store.dispatch('notification/spawnNotification', {
              type: res.status,
              message: this.$t(res.message),
              action1: { label: this.$t('OK') }
            })
          })
          .finally(() => {
            this.$bus.$emit('catalog-products-update-list')
            this.$bus.$emit('notification-progress-stop')
          })
      }
    },
    exportFile() {
      this.$bus.$emit('notification-progress-start')
      this.$store.dispatch('catalog/product/exportProductsCsv', { token: this.token, type: 'products'}).finally(() => {
        this.$bus.$emit('notification-progress-stop')
      })
    },
    async updateCategoryPosition(e) {
      let oldIndex = e.moved.oldIndex
      let newIndex = e.moved.newIndex
      let element = e.moved.element
      let category = { products_positions: {} }
      if (this.isInitPositionsRequired) {
        let index = 0,
          initPositions = {}
        this.products.forEach((product) => {
          if (element.entity_id === product.entity_id) {
            initPositions[product.entity_id] = { old: index, new: newIndex}
          } else {
            initPositions[product.entity_id] = { old: index, new: null}
          }
          index++
        })
        category.products_positions = initPositions;
        category.initPositions = true
      } else {
        category.products_positions[element.entity_id] = { new: newIndex, old: oldIndex }
      }
      let result = await this.updateCategory({ token: this.token, category_id: this.categoryId, category })
      this.updatePositions(result)
    }
  }
}
</script>
<style lang="scss" scoped>
.mobile-search {
  padding: 10px 8px;
  .mobile-icon {
    display: none;
  }
  @media (max-width: 680px) {
    .desktop-buttons {
      margin-bottom: 20px;
    }
    .mobile-icon {
      position: relative;
      display: block;
      margin-top: 12px;
      margin-left: 5px;
      .mobile-buttons {
        position: absolute;
        right: -15px;
        z-index: 1;
        padding: 20px;
        background: #ffff;
        padding-bottom: 32px;
        box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-shadow: rgb(0 0 0 / 64%) 0px 1px 2px;
        background-image: none;
        overflow: hidden;
        border-radius: 5px;
        button {
          width: 94%;
          margin-top: 10px;
        }
      }
    }
  }
}

</style>
<style lang="scss">
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.mobile-search-field {
  height: 65px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  margin: 2px 0px;
  padding: 0px;
  .v-text-field__slot label {
    color: #999999;
  }
  .v-input__control {
    padding: 16px 10px;
  }
  .v-input__control > .v-input__slot:before {
    border: none;
  }
}

.orders-table {
  table {
    @media (max-width: 1200px) {
      position: relative;
      padding-top: 70px;
      padding-left: 10px;
      .v-data-table-header-mobile {
        position: absolute;
        top: 0;
      }
    }
    @media (max-width: 600px) {
      padding-top: 10px;
      .v-data-table-header-mobile {
        display: none;
      }
    }
  }
}
.sortable-ghost {
  color: white;
}
.sortable-ghost .item-price {
  @media (max-width: 600px) {
    color: white;
  }
}
.sortable-chosen {
  background-color: white;
}
.sortable-drag, .sortable-drag .item-price {
  color: #f5a623;
}
.sortable-drag .item-price {
  @media (max-width: 600px) {
    color: #f5a623;
  }
}
</style>
