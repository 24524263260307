<template>
  <section>
    <category-products-list-table :loading="loading" :products="products" :pagination="pagination" :category-id="categoryId" @searched="search"
                         @update:options="updateOptions" :showSort="showSort"
    />
    <product-edit-modal v-if="productEdit && !addNew" :product-id="productEdit" />
    <product-edit-modal v-else-if="addNew" :product-id="0" mode="new" :category-id="categoryId" @created="addProductToCategory" />
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import CategoryProductsListTable from '@/components/Catalog/Categories/CategoryProductsListTable'
import ProductEditModal from '@/components/Catalog/ProductEditModal'

export default {
  name: 'CategoryProductsList',
  components: {
    CategoryProductsListTable,
    ProductEditModal
  },
  props: {
    categoryId: {
      type: Number,
      required: true
    },
    showSort: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      productEdit: false,
      addNew: false,
      pagination: {
        total: 0,
        options: {
          itemsPerPage: 10,
          page: 1
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getCategoryProducts: 'catalog/category/getCategoryProducts',
      getCategoryProductsLoading: 'catalog/category/getCategoryProductsLoading',
      token: 'user/getAccessToken'
    }),
    products () {
      return this.getCategoryProducts(this.categoryId)
    },
    loading () {
      return this.getCategoryProductsLoading(this.categoryId)
    }
  },
  methods: {
    ...mapActions({
      loadCategoryProducts: 'catalog/category/loadCategoryProducts',
      updateProductInState: 'catalog/category/updateProductData',
      addCategoryProduct: 'catalog/category/addCategoryProduct'
    }),
    addProductToCategory (product) {
      this.addCategoryProduct({ category_id: this.categoryId, product })
      this.pagination.total++
    },
    updateProductData({ product_id, product }) {
      if (!this.products.find(p => p.entity_id === product_id)) {
        return
      }
      this.updateProductInState({ category_id: this.categoryId, product_id, product })
    },
    updateProductEditModal(productId) {
      this.productEdit = productId
    },
    updateAddNewModal (value) {
      this.productEdit = false
      this.addNew = value
    },
    updateOptions (value) {
      this.loadCategoryProducts({ category_id: this.categoryId, token: this.token, params: {
        ipp: value.itemsPerPage, page: value.page
      } })
        .then(result => {
          this.pagination.total = result.data.total
        })
    },
    search (value) {
      this.searchTimeout = setTimeout(() => {
        this.processSearch(value)
        clearTimeout(this.searchTimeout)
      }, 500)
    },
    processSearch (value) {
      this.loadCategoryProducts({ category_id: this.categoryId, token: this.token, params: {
          ipp: this.pagination.options.itemsPerPage, page: 1, search: value
        } })
        .then(result => {
          this.pagination.total = result.data.total
        })
    }
  },
  mounted () {
    this.loadCategoryProducts({ category_id: this.categoryId, token: this.token, params: {
        ipp: this.pagination.options.itemsPerPage, page: 1
      } })
      .then(result => {
        this.pagination.total = result.data.total
      })
    this.$bus.$on('catalog-product-update', this.updateProductData)
    this.$bus.$on('catalog-product-edit', this.updateProductEditModal)
    this.$bus.$on('catalog-product-new', this.updateAddNewModal)
  },
  beforeDestroy () {
    this.$bus.$off('catalog-product-update', this.updateProductData)
    this.$bus.$off('catalog-product-edit', this.updateProductEditModal)
    this.$bus.$off('catalog-product-new', this.updateAddNewModal)
  }
}
</script>
