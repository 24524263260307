<template>
  <v-layout :class="{'layout-mobile': !desktop}">
    <div class="desktop">
      <v-flex xs12 sm12 md4>
        <tree :active-category="selected" @selected="selectCategory" @add-new-category="setAddNewCategory" />
      </v-flex>

      <v-flex xs12 sm12 md12>
        <div class="no-category-selected" v-if="!selected && !add_new">
          {{ $t('Please, select category or') }} <a @click="setAddNewCategory(true)">{{ $t('create a new one') }}</a>
        </div>
        <category-edit-form v-else-if="!add_new" @close="closeCategoryForm" :key="selected" :category-id="selected" />
        <category-edit-form v-else key="add_new_form" @created="selectCategory" @close="closeCategoryForm" :category-id="0" mode="new" />
      </v-flex>
    </div>
    <div class="mobile">
      <div class="add-new-category-btn">
        <v-btn width="100%" color="primary" dark @click="openCreateModal">{{ $t('Add new category') }}</v-btn>
      </div>
      <v-list dense nav>
        <v-treeview
          :items="getTree"
          item-key="entity_id"
          activatable
          dense
          transition
          @update:active="handleActiveUpdate"
          :active.sync="active"
        >
          <template v-slot:label="{ item }">
            <div class="title-tree"><v-icon class="mr-3" color="red" v-if="item.product_count === 0">mdi-alert</v-icon> {{ $t(item.name) }} </div>
          </template>
        </v-treeview>
      </v-list>
      <v-dialog v-model="optionEditModal" persistent width="100%" @keydown.esc="optionEditModal = false">
        <category-edit-form v-if="!add_new" @close="closeCategoryForm" :key="selected" :category-id="selected" />
        <category-edit-form v-else key="add_new_form" @created="selectCategory" @close="closeCategoryForm" :category-id="0" mode="new" />
      </v-dialog>
    </div>
  </v-layout>
</template>
<script>
import Tree from '@/components/Catalog/Categories/Tree'
import CategoryEditForm from '@/components/Catalog/Categories/CategoryEditForm'
import { mapGetters } from 'vuex';

export default {
  name: 'CatalogCategories',
  components: {
    Tree,
    CategoryEditForm
  },
  data () {
    return {
      active: [],
      selected: null,
      add_new: false,
      showMenu: true,
      optionEditModal: false,
      isUserAction: true
    }
  },
  methods: {
    selectCategory (data) {
      this.add_new = false
      if (!this.desktop) {
        this.showMenu = false
      }
      if (Array.isArray(data)) {
        this.selected = data.length ? data[0] : null
      } else {
        this.selected = data
      }
      if (!this.desktop) {
        this.optionEditModal = false
      }
    },
    closeCategoryForm (resp) {
      this.isUserAction = false;
      this.add_new = false
      if (!this.desktop) {
        this.showOrHidden()
      }
      if (resp === 'delete') {
        this.active = []
        this.selected = null
      }
      this.optionEditModal = false
      this.$nextTick(() => {
        this.isUserAction = true;
      });
    },
    handleActiveUpdate(activeItems) {
      if (this.isUserAction && activeItems.length > 0) {
        this.openModal(activeItems[0]);
      }
    },
    setAddNewCategory (value) {
      this.add_new = value;
      this.selected = null;
    },
    showOrHidden () {
      this.showMenu = !this.showMenu
    },
    openModal (categoryId) {
      this.selectCategory(categoryId)
      this.optionEditModal = true
    },
    openCreateModal () {
      this.setAddNewCategory(true);
      this.optionEditModal = true
    }
  },
  mounted () {
    this.$bus.$on('close-category-form', this.closeCategoryForm)
    this.$bus.$on('show-or-hidden-menu', (value) => {
      this.showMenu = value
    })
  },
  beforeDestroy() {
    this.$bus.$off('close-category-form', this.closeCategoryForm)
    this.$bus.$off('show-or-hidden-menu')
  },
  computed: {
    ...mapGetters({
      getTree: 'catalog/category/getTree'
    }),
    desktop () {
      return window && window.innerWidth > 1200
    }
  }
}
</script>
<style lang="scss" scoped>
.show-mobile {
  display: none;
  @media (max-width: 1200px) {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.layout-mobile {
  @media (max-width: 1200px) {
    display: block;
    padding: 0 10px;
  }
}
.no-category-selected {
  position: relative;
  width: 450px;
  margin: auto;
  top: 28%;
  border: #f5a623 dashed 1px;
  padding: 50px;
  &:hover {
    border: #fde0bb dashed 1px;
  }
  @media (max-width: 460px) {
    width: auto;
  }
}
.button-open-menu {
  display: flex;
  align-items: center;
}

.desktop {
  display: flex;
  width: 100%;
  @media (max-width: 1263px) {
    display: none;
  }
}
.mobile {
  display: none;
  @media (max-width: 1263px) {
    width: 100%;
    display: block;
    .title-tree {
      font-size: 18px;
    }
  }
}
.add-new-category-btn {
  button {
    width: 100%;
    margin-top: 10px;
  }
}
.active {
  .v-list-item__title {
    color: #f5a623;
  }
  &:before {
    color: #f5a623;
    opacity: 0.12;
  }
}
.title-tree {
  display: flex;
  align-items: center;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1rem;
}
</style>
